<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Карточка этапа</p>
    </div>
    <div>
      <div class="form-group required">
        <ComponentInput v-model="card.name" label="Название" required maxLength="255" placeholder="Введите название" />
      </div>
      <div class="form-group">
        <label class="form-label">Иконка</label>
        <div class="input-group-wrapper w-100">
          <Multiselect
            v-model="card.iconId"
            :options="icons"
            valueProp="id"
            label="text"
            trackBy="text"
            placeholder="Не указано"
            noOptionsText="Список пуст"
            noResultText="Ничего не найдено"
          >
            <template v-slot:singlelabel="scope">
              <div class="stage stage__selected" :data-code="scope.value.code">
                <IconComponent class="stage__icon" :name="`stages/${scope.value.code}`"/>
                <span class="stage__text">{{ scope.value.text }}</span>
              </div>
            </template>
            <template v-slot:option="scope">
              <div class="stage" :data-code="scope.option.code">
                <IconComponent class="stage__icon" :name="`stages/${scope.option.code}`"/>
                <span class="stage__text">{{ scope.option.text }}</span>
              </div>
            </template>
          </Multiselect>
        </div>
      </div>

      <div class="filter-trigger" :class="{ active: openAdditional }" @click="openAdditional = !openAdditional">
        <p>Дополнительные поля</p>
      </div>

      <div v-if="openAdditional">
        <div class="row">
          <div class="form-group">
            <ComponentInput v-model="card.sequence" label="Последовательность" placeholder="Введите" />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput v-model="card.previousStage" label="Предшествующий этап" placeholder="Введите" />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput v-model="card.nextStage" label="Последующий этап" placeholder="Введите" />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput v-model="card.requiredDescription" label="Обязательность" placeholder="Введите" />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput v-model="card.tradingOperationType" label="Тип торговой операции" placeholder="Введите" />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput v-model="card.relatedProcedures" label="Список связанных процедур" placeholder="Введите" />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput v-model="card.production" label="Производство" placeholder="Введите" />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput v-model="card.contracting" label="Заключение контракта" placeholder="Введите" />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.transportationPreparing"
              label="Подготовка к отправке"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.deliveringCheckpoint"
              label="Перевозка до пункта пропуска"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.stateControlPassing"
              label="Прохождение госконтроля в пункте пропуска"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput v-model="card.actualExport" label="Фактический вывоз" placeholder="Введите" />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput v-model="card.contractPayments" label="Расчеты по контракту" placeholder="Введите" />
          </div>
        </div>
      </div>

      <div class="form-group mt-10">
        <div class="checkboxInput">
          <input type="checkbox" v-model="card.isActive" id="chActive" />
          <label for="chActive">Запись активна</label>
        </div>
      </div>
    </div>
    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock btn-type="secondary" title="Отмена" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import Multiselect from '@vueform/multiselect';
  import { useVuelidate } from '@vuelidate/core';

  import Constants from '@/common/constants';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import NsiApi from '@/modules/nsi/api/nsi';

  import ComponentInput from '../../../common/components/ComponentInput';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import API from '../../navigator/api/stage';

  export default {
    name: 'StageCard',
    components: {
      ButtonStock,
      ComponentInput,
      Multiselect,
      IconComponent
    },
    data() {
      return {
        id: this.$route.params.id,
        card: {
          isTemplate: true,
        },
        icons: [],
        openAdditional: false,
      };
    },
    created() {
      this.loadDictionaries();
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          API.find(this.id).then((response) => {
            this.card = response.data;
          });
        }
      },
      loadDictionaries() {
        NsiApi.search('nsisvg', { pageNumber: 1, pageSize: 0, isActive: true }).then((response) => {
          this.icons = response.data.items.map((x) => ({
            id: x.id,
            text: x.name,
            code: x.code,
            content: x.content
          }));
        });
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }
        console.log(this.card);
        API.update(this.card)
          .then((response) => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (response && !this.card.id) {
              this.$router.push({ name: 'Stages', params: { id: response.data.id, action: 'edit' } });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'Stages' });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        card: {},
      };
    },
  };
</script>

<style lang="scss" scoped>
  .filter-trigger {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    margin-bottom: 16px;
    cursor: pointer;
    &:after {
      content: ' ';
      display: block;
      width: 8px;
      height: 8px;
      border-left: 2.5px solid black;
      border-bottom: 2.5px solid black;
      transform: rotate(315deg);
      transition: 0.3s;
      margin-left: 8px;
    }
    &.active {
      &:after {
        transform: rotate(135deg);
      }
    }
  }

  .stage {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  .stage__selected {
    padding-inline: 12px;
  }

  .stage__icon {
    flex: 0 0 auto;
    width: 18px;
    height: 18px;
  }

  .stage__text {
    flex: 1 1 0%;
  }
</style>
